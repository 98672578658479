import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SalePreview from '../components/salePreview'

import './sale.scss'

const Sale = ({ data }) => (
  <Layout>
    <SEO title="Sales" />
    <div className="sales-title">
      <h1>For Sale</h1>
    </div>
    <div className="sales-body">
      {data.allMarkdownRemark.edges.map(edge => {
        return <SalePreview html={edge.node.html} {...edge.node.frontmatter} />
      })}
    </div>
  </Layout>
)

export const query = graphql`
  query SalePageQuery {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/salePosts/" } }) {
      edges {
        node {
          html
          id
          fileAbsolutePath
          frontmatter {
            path
            title
            date
            description
            price
          }
        }
      }
    }
  }
`

export default Sale
