import React from 'react'
import moment from 'moment'
import './salePreview.scss'

function SalePreview({ title, date, description, price, html }) {
  const formattedDate = moment(date).format('YYYY-MM-DD hh:mm')
  return (
    <article className="sale-container">
      <div className="sale-row">
        <span className="sale-title">{title}</span>
        <span className="sale-create-date">
          <time datatype={formattedDate}>
            <i>Posted: {formattedDate}</i>
          </time>
        </span>
      </div>
      <hr />
      <p className="sale-description">
        <i>Description: {description}</i>
      </p>
      <p className="sale-price">{price}</p>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </article>
  )
}

export default SalePreview
